/* variables ------------------------------------ */
/*	note: changes in this doc should be copied over to _VarsDark.scss, with any necessary mods */

// font colors only
$font-link: #0066cc;

$font-blue: #0074b2;
$font-blue-light: #d8e7f1;
$font-blue-bright: #33ddf9;
$font-black: #000;
$font-charcoal: #94AEB4;
$font-charcoal-dark: #638a92;
$font-dark:#053032;
$font-gray: gray;
$font-form-gray: #999; /* used for some disabled elements and borders */
$font-green: #43B724;
$font-green-dark: #2d8515;
$font-green-hourly: #51A351;
$font-purple: #de14f0;
$font-purple-dark: #93199e;
$font-red: #C75C54;
$font-red-dark: #9b3029;
$font-red-clockout: #d9534f;
$font-teal: #00A8B2;
$font-teal-dark: #446B6D;
$font-yellow: #eaa21e;
$font-white: white;

// font color by type
$font-tab: #6A888F;

// background & base colors
$bg-blue: #0074b2;
$bg-blue-light: #d8e7f1; /* project timers, weeks view, entry... */
$bg-charcoal-light: #94AEB42E;
$bg-blue-bright: #33ddf9;	/* "Other1" timeoff	*/
$bg-charcoal: #94AEB4;
$bg-dark:#053032;
$bg-black: #000;
$bg-dark-gray:#696969;	/* aka dimgray */
$bg-gray: gray;
$bg-light-gray: #DAE0E1; /* used in #content background! */
$bg-very-light-gray: #E4E4E4; /* only in Expenses? */
$bg-form-gray: #999; /* used for some disabled elements and borders */
$bg-emph: #F7F9FA; /* grey found in tables for even numbered rows  */
$bg-menu-background-gray: #EFF4F5;
$bg-menu-gray: #EFEFEF;
$bg-tab-unselected: $bg-menu-gray; /* changed from #e3e3e3; for better contrast	*/
$bg-gray-light: #FFFFFF; /* white, not gray? */
$bg-green: #43B724;
$bg-green-dark: #2d8515;
$bg-green-light: #D7ECD4; /* hourly edit record */
$bg-green-hourly: #51A351;
$bg-orange: #e3974a;
$bg-purple: #de14f0;
$bg-purple-dark: #93199e;
$bg-red: #C75C54;
$bg-red-dark: #9b3029;
$bg-red-clockout: #d9534f;
$bg-teal: #00A8B2;
$bg-teal-gray: #628990;
$bg-teal-dark: #006170;
$bg-teal-light: #63ebf25a;
$bg-teal-tabs: #6A888F;
$bg-white: white;
$bg-yellow: #FFC43E;
$bg-yellow-alpha: rgba(255, 255, 0, .35);
$bg-pink: #f1a9a4; /* background color for unpublished calendar entries */
$bg-calendar-weekend : #f1f5f6;

/* Tab/BizCard specific colors */
$bg-hourly: $bg-green-hourly;
$font-hourly: $bg-green-hourly;
$bg-project: $bg-teal-dark;
$font-project: $bg-teal-dark;
$bg-expenses: #400010;
$font-expenses: #400010;
$bg-user-settings: $font-teal-dark;
$font-user-settings: $font-teal-dark;
$bg-hrdocs: $bg-dark;
$font-hrdocs: $font-dark;

// font families
$font-primary: Helvetica, Arial, sans-serif;

// consistent spacing
$padding-sm: 15px;
$padding-md: 25px;
$padding-lg: 45px;
$padding-xl: 60px;

// transitions
$transition-default: all 0.5s;

/* Breakpoints used for @media tags based on Bootstrap (same as Geneva) */
$phoneBreakpoint:            576px;
$phoneLandscapeBreakpoint:   667px;  /* Not really used, so avoid unless necessary. Based on iPhone 6/7/8. not in bootstrap */
$tabletBreakpoint:           768px;  /* based on iPad mini, not pro */
$tabletLandscapeBreakpoint:  1024px; /* Not really used, so avoid unless necessary. Based on iPad mini. not in bootstrap */
$largeBreakpoint:            992px;
$xLargeBreakpoint:           1200px;


// extends & inheritance
%shadow {
	-webkit-box-shadow: 0px 0px 5px 1px rgba(12,56,68,0.2);
	box-shadow: 0px 0px 5px 1px rgba(12,56,68,0.2);
	}

%width-100 {
	width: 100%;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
	}

/*   Grid based "tables"   */
$colControl: 3em;
$colName: auto;
$colXS: 5em;
$colSM: 6em;
$colMD: 7em;
$colLG: 8em;
$colXL: 9em;
$colIcon: 4em;

/*  To "unify" z-index settings  */
$level-0: 0;
$level-1: 100;
$level-2: 200;
$level-3: 300;
$level-4: 400;
$level-5: 500;
$level-6: 600;
$level-7: 700;
$level-8: 800;
$level-9: 900;
$level-10: 1000;
$level-11: 1100;
/* reserved for the timeout, you should never top this. Ever. Fix YOUR code if it's lame enough to be higher than this! */
$level-absolute-top: 15000;	

* {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	color:black !important;
	background-image:none !important;
	background-color:transparent !important;
}
html,body {
	width:100% !important;
	padding:0 0 !important;
	margin:0 0 !important;
}

body {
	font-family: "Roboto", Arial, Helvetica, sans-serif !important;
	font-size: 7pt;
	line-height: 8.5pt !important;
	font-weight:normal !important;
	text-decoration:none !important;
	color:black !important;
}
#pageWrapper {
	width:100% !important;
	padding:0 0 !important;
	margin:0 0 !important;
}
#contentWrapper {
	float:left;
	clear:left;
	width:100%;
	margin-top: 0 !important;
	top: 0 !important;
}

td.ltg, th.ltg, div.ltg, span.ltg, a.ltg, .ltg * {
	color:#EEE !important;
}
td.ltg img {
	opacity:0.3 !important;
	background-color:white !important;
}

table {
	font-size:inherit;
	font-weight:normal;
	border-width:1px;
	border-color:#BBB;
	border-style:none;
	margin:0;
	padding:0;
	border-spacing:0px;
}
td {
	font-size:inherit;
	font-weight:normal;
	border-color:#BBB;
	border-width:1px;
	border-style:none;
}
th {
	font-size:inherit;
	line-height:9pt;
	font-weight:bold;
	padding:0 1px;
	border-color:#BBB;
	border-width:1px;
	border-style:none;
}
th span, th div {
	font-size:inherit;
	line-height:9pt;
	font-weight:bold;
	padding:0 1px;
}
b, strong {
	font-weight:bold;
}
h1 {
	font-size: 14pt;
	line-height: 1em;
	font-weight: normal;
	text-align:left;
	color: #555;
	text-transform:uppercase;
}
h2 {
	font-size:12pt;
	font-weight:bold;
	padding-bottom:0.25em;
}
h3,h4 {
	font-size:10pt;
	font-weight:bold;
	margin:0;
	padding-top:0.25em;
	padding-bottom:0.25em;
}

table, table *, table[style],
td, td[style], td *,
th, th[style], th *,
div, div[style], div *,
span, span[style], span *  {
	color:black;
	background-image:none;
	background-color:transparent;
}

#topmenu,#sidemenu,#header,#footer {
	display:none;
}
#printHeader {
	display:block;
	border-bottom:1px solid #BBB;
	margin-bottom:1em;
}
#content {
	display:block;
	width:100%;
}
div.dataTableScrollable {
	width:100% !important;
	height:auto;
}

form {
	display:inline;
}
a {
	text-decoration:none;
	color:black;
}
input, select, textarea {
	vertical-align:middle;
}
div.rel { position:relative; }
div.abs { position:absolute; }
div.clear { clear:both; }
.floatLeft { float:left; }
.floatRight { float:right; }

div.table { display: table; }
div.table > div.row { display: table-row; }
div.table > div.row > div.cell { display: table-cell; }
div.table > div.row > div.cell.left { padding-right: 5px; font-weight: bold; white-space: nowrap; }
div.table > div.row > div.cell.right { padding-right: 5px; }

td.iconCell {
	width:auto !important;
	white-space:nowrap !important;
}
td.iconCell .floatLeft {
	display:inline-block;
	float:none;
	clear:none;
}
td.iconCell span.noDisplay {
	white-space: pre !important;
	width:auto !important;
	float:none;
	clear:none;
}
td.iconCell span.noDisplay:not(:first-child) {
	margin-left:3pt;
}

/* tables ---------------------------------- */
table.dataTable, table.tablePad {
	width:100%;
	margin:0;
	padding:0;
	border-color:#BBB;
	border-width:1px;
	border-style:none none solid solid;
}
table.smallData {
	width:100%;
	margin:0;
	padding:0;
	border-color:#BBB;
	border-width:1px;
	border-style:none none solid solid;
}
table.dataTable tbody {
 border-bottom:1px solid #BBB;
}
table.dataTable th, table.tablePad th {
	padding:0 1px 0 1px;
	text-align:left;
	font-size:inherit;
	font-weight:bold;
	border-style:solid solid none none;
	vertical-align:bottom;
}
table.dataTable caption, table.tablePad caption {
	padding:0 1px 0 1px;
	text-align:left;
	font-size:inherit;
	font-weight:bold;
	border-width:1px 1px 0px 1px;
	border-color:#BBB #BBB #000 #BBB;
	border-style:solid solid none solid;
	vertical-align:bottom;
}
table.dataTable td, table.tablePad td {
	padding:0 2px 0 2px;
	border-style:solid solid none none;
}
table.smallData tr th, table caption {
	padding:0 2px 0 2px;
	font-size:inherit;
	font-weight:bold;
	text-align: left;
	border-style:solid solid none none;
}
table.smallData tr td {
	padding:0 2px 0 2px;
	font-size:inherit;
	font-weight:normal;
	border-style:solid solid none none;
}

/*	Adjusts table width because stupid FF always resizes .tableScrollWrapper when you print */
.table-print-correction {
	max-width: 100% !important;
	}

/*  For grid-based table replacements (esp nested tables)  */
.grid-container {
    display: grid;
    background-color: white;
    padding: 0px;
}
.payrollGrid {
	border-style: none;
	border-bottom: solid 1px $bg-dark-gray;
}
.control-col {
	visibility: hidden;
	width: 0;
}
.col-checkbox {
	display: none !important;
}
/*  setting up the columns, 0 gets rid of the open/close toggle  */
.container-9 {
	grid-template-columns: 0 $colName $colLG $colLG $colLG $colIcon $colIcon $colXL $colXL;
}
.container-10 {
	grid-template-columns: 0 $colName $colLG $colLG $colLG $colLG $colIcon $colIcon $colXL $colXL;
}
.container-11 {
	grid-template-columns: 0 $colName $colLG $colLG $colLG $colLG $colLG $colIcon $colIcon $colLG $colLG;
}
.container-12 {
	grid-template-columns: 0 $colName $colLG $colLG $colLG $colLG $colLG $colLG $colIcon $colIcon $colLG $colLG;
}
.container-13 {
	grid-template-columns: 0 $colName $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colIcon $colIcon $colLG $colLG;
}
.container-14 {
	grid-template-columns: 0 $colName $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colIcon $colIcon $colLG $colLG;
}
.container-15 {
	grid-template-columns: 0 $colName $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colIcon $colIcon $colLG $colLG;
}
.container-16 {
	grid-template-columns: 0 $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colIcon $colIcon $colMD $colMD;
}
.container-17 {
	grid-template-columns: 0 $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS  $colIcon $colIcon $colMD $colMD;
}
.container-18 {
	grid-template-columns: 0 $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colIcon $colIcon $colMD $colMD;
}
.container-19 {
	grid-template-columns: 0 $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colIcon $colIcon $colMD $colMD;
}
.container-20 {
	grid-template-columns: 0 $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colIcon $colIcon $colMD $colMD;
}

.preliminaryPayrollGrid {  /* first two cols are ignored with preliminary, so special crap is needed */
	&.container-9 {
		grid-template-columns: $colName $colLG $colLG $colLG $colIcon $colIcon $colXL $colXL;
	}
	&.container-10 {
		grid-template-columns: $colName $colLG $colLG $colLG $colLG $colIcon $colIcon $colXL $colXL;
	}
	&.container-11 {
		grid-template-columns: $colName $colLG $colLG $colLG $colLG $colLG $colIcon $colIcon $colLG $colLG;
	}
	&.container-12 {
		grid-template-columns: $colName $colLG $colLG $colLG $colLG $colLG $colLG $colIcon $colIcon $colLG $colLG;
	}
	&.container-13 {
		grid-template-columns: $colName $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colIcon $colIcon $colLG $colLG;
	}
	&.container-14 {
		grid-template-columns: $colName $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colIcon $colIcon $colLG $colLG;
	}
	&.container-15 {
		grid-template-columns: $colName $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colSM $colIcon $colIcon $colLG $colLG;
	}
	&.container-16 {
		grid-template-columns: $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colIcon $colIcon $colMD $colMD;
	}
	&.container-17 {
		grid-template-columns: $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS  $colIcon $colIcon $colMD $colMD;
	}
	&.container-18 {
		grid-template-columns: $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colIcon $colIcon $colMD $colMD;
	}
	&.container-19 {
		grid-template-columns: $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colIcon $colIcon $colMD $colMD;
	}
	&.container-20 {
		grid-template-columns: $colName $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colXS $colIcon $colIcon $colMD $colMD;
	}
}



/* to make a "row" go the full width of the "table" e.g. like colspan in html */
.g-wide-10 { grid-column: 1/10; }
.g-wide-11 { grid-column: 1/11; }
.g-wide-12 { grid-column: 1/12; }
.g-wide-13 { grid-column: 1/13; }
.g-wide-14 { grid-column: 1/14; }
.g-wide-15 { grid-column: 1/15; }
.g-wide-16 { grid-column: 1/16; }
.g-wide-17 { grid-column: 1/17; }
.g-wide-18 { grid-column: 1/18; }
.g-wide-19 { grid-column: 1/19; }
.g-wide-20 { grid-column: 1/20; }

.g-heading {
	display: block;
	border-bottom: solid 1px $bg-dark-gray;
}
.g-heading.g-head-1 {
	display: inline-block;
	border-bottom: solid 1px $bg-dark-gray;
}
#checkAllPayrollDataWrapper {
	width: 0 !important;
	display: none !important;
}

.name-col {
	font-weight: bold;
}
h4.printDateHeading {
	margin-bottom: 20px;
}

table caption {
	font-size:9pt;
}
tr.payrollExpandRow + tr.notes table {
	border-bottom-style:solid;
}
tr.payrollExpandRow + tr.notes table > * > tr > *:first-child {
	border-left-style:none;
}
tr.payrollExpandRow + tr.notes table > * > tr > *:last-child {
	border-right-style:none;
}
tr.payrollExpandRow + tr.notes table.smallData > tbody > tr > td {
	padding:2px;
}
tr.payrollExpandRow + tr.notes table.smallData table > * > tr > *:first-child {
	padding-left:0;
}
tr.smallHeaders td, tr.smallHeaders th {
	font-weight:bold;
	font-size:inherit;
	border-style:solid solid none none;
}
.smallHeaders > td:last-child, .smallHeaders th:not(:last-child) {
	border-right-style:none;
}
table.dataTable > tbody > tr > td > table > tbody > tr.noTopBorderRow > td {
	border-top: 1px solid #CCC !important;
}

.notes table  {
	border-width:1px;
	border-color:#BBB;
	border-style:none;
}
div.notes, tr.notes td {
	padding:0;
	border-top-style:none;
}
.tabControl {
	display:none;
}
div.tabs, div.tabsHourly, div.tabsProject {
	margin:0;
	padding:0;
	display:block;
	clear:both;
}
div.tabs a.tab, div.tabs.noPrint {
	display:none;
}
div.tabs a.tabOn {
	display:inline-block !important;
	color:#000;
	font-size:8pt;
	padding:1pt 3pt 1pt 3pt;
	border-width:1px;
	border-style:solid solid none solid;
	border-color:#AAA;
}

.largeText, .dataTable tr.largeText td {
	font-size:10pt;
}
.bold {
	font-weight:bold;
}
button,input:button,input:submit,input:reset {
	display:none;
}
.dayOfWeek {
	font-size:inherit;
	font-weight:normal;
}
.grayText {
	color:#999;
}
#tooltip, #tooltip h3, #tooltip div {
	display:none;
}
.photoBorder {
	border-width:1px;
	border-style:solid;
}
div.screenPadding {
	padding:0;
}
.errorMsg, .confirmation, .instructions {
	display:block;
	font-family:Georgia, "Times New Roman", Times, serif;
	border:1px solid black;
	padding:3pt;
	margin-bottom:5pt;
}
#WatermarkText {
	font-family:"Arial Black", Gadget, sans-serif;
	display:block;
	padding:3pt;
	border:1px solid black;
	margin-bottom:5pt;
}
.WatermarkTextFloatRight {
	float:right;
}
td.employeeLinkCell {
	border-style:none;
}
table.noBorderTopBottom, table.noBorderTopBottom > tbody > tr >  td {
	border-style:none solid none solid !important;
	border-width:1px;
	border-color:#BBB;
}
table.borderNone, table.borderNone td, td.borderNone {
	border-style:none !important;
	border-width:1px;
	border-color:#BBB;
	border-spacing:0 !important;
}
/* hierarchy */
div.hierarchyControlWrapper {
	position:relative;
	display:inline;
	vertical-align:top;
	text-align:left;
	width:0;
	height:0;
	padding:0;
	margin:0;
}
div.hierarchyControl {
	position:absolute;
	text-align:left;
	z-index: $level-1;
	width:12px;
	height:12px;
	padding:0;
	margin:0;
	border-top:12px solid #BBB;
}
div.minusAdmin, div.minusSuper, div.plusAdmin, div.plusSuper, div.blankAdmin, div.blankSuper  {
	padding:0;
	width:12px;
	height:12px;
	vertical-align:middle;
	z-index: $level-2;
}
div.hierarchyDivWrapper {
	position:relative;
	height:0;
}
div.hierarchyDiv {
	position:absolute;
	display:inline;
	left:5px;
	top:-13px;
	z-index: $level-1;
	margin:0;
	padding:0;
	overflow:visible;
	background:transparent;
}
div.hierarchyDiv div {
	float:left;
	width:8px;
	height:18px;
	margin:0;
	background:transparent;
}
div.hierarchyDiv div.line0 {
	width:11px;
	height:18px;
}
div.hierarchyDiv div.line1 {
	border-left:2px solid #BBB;
	border-bottom:2px solid #BBB;
}
div.hierarchyDiv div.line2 {
	border-left:2px solid #BBB;
}
div.hDiv {
	position:relative;
	text-align:left;
	z-index: $level-1;
	font-weight:bold;
	white-space:nowrap;
	zoom:1; /* prevents disappearing in IE6 */
}
div.weekViewNextLastControlDivWrapper {
	vertical-align:top;
}

/* Start checkbox list styles ------------ */
	div.checkboxAreaWrapper {
		display:block;
		zoom:1;
		border-style:solid;
		border-color:#BBB;
		border-top:none;
		border-width:1px;
		padding:5px 5px 0 5px;
		margin:0 0 10px 5px;
		border-bottom-left-radius:5px;
		border-bottom-right-radius:5px;
	}
	div.checkboxAreaWrapper div.checkboxAreaMessage {
		padding:2px 0;
		margin:0 0 2px 0;
		border-bottom:1px solid #BBB;
	}
	div.checkboxes, span.checkboxes {
		border: 2px solid #BBB;
		padding: 5px 5px 5px 30px;
		text-align:left;
		vertical-align:top;
		border-radius:4px;
		margin:0 0 5px 0;
		padding:3px;
		zoom:1;
	}
	div.checkboxesBlue, span.checkboxesBlue {
		border: 2px solid #BBB;
		text-align:left;
		vertical-align:top;
		border-radius:4px;
		padding:5px;
		margin:0 0 5px 0;
		padding:3px;
		zoom:1;
	}
	div.checkboxAreaWrapper table tr td {
		padding:0;
	}
	div.checkboxAreaWrapper table tr td label {
		display:block;
		position:relative;
		overflow:hidden;
		width:auto;
		height:18px;
		border-radius:4px;
		padding:0;
		cursor:default;
		clear:both;
	}
	div.checkboxAreaWrapper table tr td label input {
		display:block;
		float:left;
		clear:right;
		border:none;
		padding:0;
		vertical-align:text-bottom;
		margin:2px;
		zoom:1;
		position:absolute;
		top:0;
		left:0;
	}
	div.checkboxAreaWrapper table tr td label > div {
		display:block;
		float:left;
		clear:left;
		padding:1px 0 0 20px;
		margin:0;
		overflow:visible;
		vertical-align:text-top;
		border-radius:4px;
		line-height:1.35em;
		width:auto;
		height:auto;
	}
/* End checkbox list styles ------------ */

/* history */
td.historyCellChange div.rel {
	display:block;
	position:relative;
	padding:0;
}
td.historyCellChange div.rel div.historyArrowDiv {
	position:absolute;
	bottom:2px;
	width:0;
	height:0;
	line-height:0;
}
div.historyArrowDiv img {
	display: inline;
}


tr th[colspan], tr td[colspan] {
	display:table-cell;
	width:auto;
}

/* --- page breaking --- */
.pageBreakBefore {
	break-before:always;
	page-break-before:always;
	-webkit-page-break-before:always;
}
.pageBreakAfter {
	break-after:always;
	page-break-after:always;
	-webkit-page-break-after:always;
}
.pageBreakAvoidBefore {
	break-before:avoid;
	page-break-before:avoid;
	-webkit-page-break-before:avoid;
}
.pageBreakAvoidInside {
	break-inside:avoid;
	page-break-inside:avoid;
	-webkit-page-break-inside:avoid;
}
table tbody tr {
	break-inside: auto;
	page-break-inside: auto;
	-moz-page-break-inside: auto;
  -webkit-page-break-inside: auto;
}
table tbody tr > td {
	break-inside: auto;
	page-break-inside: auto;
	-moz-page-break-inside: auto;
	-webkit-page-break-inside: auto;
}

/* start locktables css */
table.fixed {
	table-layout:auto !important;
}
table.fixed > * > tr > *,
table.fixed caption {
	word-wrap: break-word !important;
	max-width: none !important;
}
table.fixed > * > tr > *:not([width]) {
	min-width:none !important;
	max-width:none !important;
}
/* end locktables css */

tr.padTopBottomOfCellsInRow > td {
	padding-top:0.5em;
	padding-bottom:0.5em;
}

.ui-widget, .ui-dialog, .ui-tooltip { display:none !important; }

/* --- this prevents wkhtmltopdf from making weird overlaps in output when tables are nested with thead/tfoot --- */
/* thead, tfoot { display:table-row-group; } */

/* --- noPrint last --- */
table.noPrint, div.noPrint, a.noPrint, div.noPrint, span.noPrint, img.noPrint, ul.noPrint, .debug {
	display:none !important;
	width:0;
	border:none;
}
tr.noPrint {
	display:none !important;
}
td.noPrint, th.noPrint {
	position:absolute;
	left:-100%;
	top:-100%;
	display:inline-block !important;
	overflow:hidden !important;
	max-width:0 !important;
	width:0 !important;
	padding:0 0 0 0 !important;
	border-right-style:none !important;
	color:white !important;
	margin-right: -1px !important;
}
td.noPrint *, th.noPrint * {
	display:none !important;
	color:white !important;
	font-size:0;
	line-height:0;
}
/* Hourly timesheet, account code column (screen has different width) */
th.acct-code-col {
	width: inherit;
	}

tr.notes { display:none; width: 100%; }

tr.expanded { display:table-row !important; }
tr.expanded > td:not(.noPrint), tr.expanded > th:not(.noPrint) { display:table-cell !important; }

/* -- Report Printing --  */
.hide {
	display: none !important;
	}
.hideFromPrint {
	display: none !important;
}
.showOnPrint {
	display: block !important;
}
div.legendBox {
	border: solid 1px #ccc;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 20px;
	width: 50%;
	h4 {
		margin-left: 10px;
	}
	ul {
		list-style-type: none;
	}
}
.singleLegend {
	/* default to showing only one legend until pagebreaks are toggled	*/
	display: block;
	}
.multiLegend {
	display: none;
	}
.numeric {
	text-align: right;
}

.report-signed-approved {
	font-size: 12px;
	margin-top: 20px;
	margin-bottom: 20px;
}
/* to make sure notes show properly on print */
table tr.notes.expanded td {
	line-height: 17px;
	padding-left: 20px;
	padding-right: 20px;
}
.notes.tablesorter-childRow p {
	display: none !important;
	}

.hr-table td {
	padding: .75rem !important;
}
.hr-table .hr-table-user tr td {
	padding: .25rem !important;
	border: none;
}

/* Top Summary section of Reports */
table.figuresTable {
	font-size: 9pt;
	line-height: 17px;
}

/*  Resize Printing/PDF for tables from the print modal, see _ReportPrint.js for usage   */
table.dataTable, div.payrollGrid  {
	font-size: unset;
	&.extraSmall, &.extraSmall div.g-item {
		font-size: 7pt !important;
		}
	&.small, &.small div.g-item {
		font-size: 8pt !important;
		}
	&.medium, &.medium div.g-item {
		font-size: 9pt !important;
		line-height: 20px;
		}
	&.large, &.large div.g-item {
		font-size: 10pt !important;
		line-height: 23px;
		}
	&.extraLarge, &.extraLarge div.g-item {
		font-size: 12pt !important;
		line-height: 25px;
		}
	}

.payrollGrid .g-item {
	text-align: right;
	&.name-col {
		text-align: left !important;
		}
	}

/* Calendar */
.menu-link, input[type=checkbox].day_checkbox, .full-week-checkbox-off .week_checkbox {
	display: none;
	}
/*
.grid-row{
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr !important;
	}
*/